<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 170 71"
    :width="internalWidth"
    :height="round ? internalWidth : internalHeight"
    :class="round ? 'rounded' : ''"
    :style="{
      backgroundColor,
      cursor: clickable ? 'pointer' : 'initial',
    }"
  >
    <path :fill="fill" d="M 114.069 46.366 C 114.069 51.018 112.979 55.225 110.822 58.886 C 108.665 62.541 105.677 65.462 101.932 67.557 C 98.188 69.652 93.839 70.717 89.011 70.717 C 83.657 70.717 78.67 69.521 74.18 67.164 C 69.678 64.802 66.279 61.357 64.093 56.939 L 63.991 56.734 L 71.971 51.57 L 72.09 51.832 C 73.564 55.1 75.857 57.599 78.918 59.279 C 81.985 60.953 85.379 61.807 89.005 61.807 C 91.88 61.807 94.534 61.163 96.895 59.905 C 99.25 58.647 101.153 56.876 102.554 54.65 C 103.949 52.43 104.66 49.833 104.66 46.935 C 104.66 42.272 103.192 38.799 100.306 36.619 C 97.392 34.427 93.438 33.317 88.559 33.317 L 76.941 33.317 C 73.801 33.317 71.299 32.394 69.492 30.572 C 67.685 28.756 66.77 26.268 66.77 23.176 L 66.77 0.283 L 109.89 0.283 L 109.89 9.193 L 75.722 9.307 L 75.722 22.152 C 75.722 22.846 75.953 23.398 76.433 23.848 C 76.913 24.304 77.523 24.52 78.291 24.52 L 89.796 24.52 C 97.262 24.52 103.231 26.353 107.541 29.974 C 111.867 33.607 114.058 39.124 114.058 46.36 L 114.069 46.366 Z" />
    <path :fill="fill" d="M 169.733 46.366 C 169.733 51.018 168.643 55.225 166.486 58.886 C 164.329 62.541 161.341 65.462 157.596 67.557 C 153.852 69.652 149.509 70.717 144.68 70.717 C 139.326 70.717 134.339 69.521 129.849 67.164 C 125.354 64.802 121.954 61.357 119.768 56.939 L 119.666 56.734 L 127.647 51.57 L 127.76 51.832 C 129.234 55.1 131.532 57.599 134.588 59.279 C 137.654 60.953 141.049 61.807 144.675 61.807 C 147.549 61.807 150.204 61.163 152.564 59.905 C 154.919 58.647 156.823 56.876 158.223 54.65 C 159.618 52.424 160.33 49.833 160.33 46.935 C 160.33 42.272 158.862 38.799 155.97 36.619 C 153.061 34.427 149.102 33.317 144.223 33.317 L 132.611 33.317 C 129.471 33.317 126.969 32.394 125.162 30.572 C 123.354 28.756 122.439 26.268 122.439 23.176 L 122.439 0.283 L 165.56 0.283 L 165.56 9.193 L 131.391 9.307 L 131.391 22.152 C 131.391 22.846 131.623 23.398 132.103 23.848 C 132.583 24.304 133.193 24.52 133.961 24.52 L 145.465 24.52 C 152.931 24.52 158.907 26.353 163.216 29.974 C 167.542 33.607 169.733 39.124 169.733 46.36 L 169.733 46.366 Z" />
    <path :fill="fill" d="M 54.763 26.2 C 52.154 21.799 48.658 18.309 44.275 15.747 C 39.887 13.173 34.934 11.892 29.416 11.892 C 23.898 11.892 18.939 13.219 14.562 15.86 C 10.174 18.502 6.695 22.026 4.125 26.433 C 1.55 30.84 0.268 35.725 0.268 41.088 C 0.268 46.451 1.477 51.359 3.899 55.8 C 6.317 60.247 9.626 63.811 13.823 66.498 C 18.013 69.18 22.797 70.524 28.168 70.524 C 34.2 70.524 40.293 68.366 44.642 64.044 C 45.896 62.797 47.144 61.329 47.878 59.666 L 50.166 59.666 L 50.166 68.753 L 58.671 68.753 L 58.671 41.088 C 58.671 35.571 57.366 30.607 54.757 26.2 L 54.763 26.2 Z M 39.734 59.08 C 36.639 60.958 33.194 61.892 29.416 61.892 C 25.638 61.892 22.215 60.953 19.154 59.08 C 16.093 57.201 13.67 54.696 11.891 51.553 C 10.112 48.41 9.231 44.96 9.231 41.202 C 9.231 37.444 10.118 34 11.891 30.851 C 13.67 27.709 16.093 25.203 19.154 23.325 C 22.209 21.446 25.632 20.512 29.416 20.512 C 33.2 20.512 36.639 21.451 39.734 23.325 C 42.835 25.203 45.275 27.709 47.054 30.851 C 48.827 34 49.719 37.45 49.719 41.202 C 49.719 44.954 48.827 48.41 47.054 51.553 C 45.275 54.696 42.841 57.201 39.734 59.08 Z" />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    round: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: String,
      default: '#1929c2',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
  },
  data() {
    return {
      internalWidth: 170,
      internalHeight: 71,
      ratio: 2.3943661971830985,
    }
  },
  watch: {
    width: {
      immediate: true,
      handler(val) {
        if (val) {
          const newWidth = +val
          this.internalWidth = newWidth
          this.internalHeight = newWidth / this.ratio
        }
      },
    },
    height: {
      immediate: true,
      handler(val) {
        if (val) {
          const newHeight = +val
          this.internalHeight = newHeight
          this.internalWidth = newHeight * this.ratio
        }
      },
    },
  },
}
</script>

<style>
.rounded {
border-radius: 50%;
padding: 5px;

}
</style>
